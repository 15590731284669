@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');

:root {
    --color-first: #036B52;
    --color-second: #2FC18C;
    --color-third: #421981;
    --color-fourth: #056CF9;
    --color-fifth: #056CF9;
    --text-light: #F2FFFC;
    --text-dark: #001813;
    --background: #FF7300;
}

html * {
    font-family: 'Montserrat';
    font-weight: 300;
}

.bold-paragraph {
    font-weight: 700;
}

main {
    align-items: center;
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    width: 100vw;
}

.presentation-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 40%;
}

.presentation-text p {
    width: 80%;
    color: white;
}

.landing-img {
    align-self: center;
    justify-self: end;
    height: 50%;
}

@media (min-width:1025px) {

    main {
        flex-direction: row;
        justify-content: center;
    }

    .presentation-text {
        width: 40%;
    }

    .presentation-text p {
        width: 50%;
    }

    .landing-img {
        height: 90%;
        align-self: end;
        justify-self: end;
    }

}